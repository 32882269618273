.flip-clock {
    text-align: center;
    perspective: 400px;
    margin: 20px auto;
  }
  .flip-clock *,
  .flip-clock *:before,
  .flip-clock *:after {
    box-sizing: border-box;
  }
  .flip-clock__piece {
    display: inline-block;
    margin: 0 5px;
  }
  .flip-clock__slot {
    font-size: 2vw;
    color: #fff;
  }
  .card {
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: 9vw;
    line-height: 0.95;
  }
  .card__top,
  .card__bottom,
  .card__back::before,
  .card__back::after {
    display: block;
    height: 0.72em;
    color: #ccc;
    background: #222;
    padding: 0.25em 0.25em;
    border-radius: 0.15em 0.15em 0 0;
    backface-visiblity: hidden;
    transform-style: preserve-3d;
    width: 1.8em;
    transform: translateZ(0);
  }
  .card__bottom {
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px #000;
    background: #393939;
    border-radius: 0 0 0.15em 0.15em;
    pointer-events: none;
    overflow: hidden;
  }
  .card__bottom::after {
    display: block;
    margin-top: -0.72em;
  }
  .card__back::before,
  .card__bottom::after {
    content: attr(data-value);
  }
  .card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
  }
  .card__back::before {
    position: relative;
    z-index: -1;
    overflow: hidden;
  }
  .flip .card__back::before {
    -webkit-animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
            animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    transform-origin: center bottom;
  }
  .flip .card__back .card__bottom {
    transform-origin: center top;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
            animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
  }
  @-webkit-keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%,
    99% {
      opacity: 0.99;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }
  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%,
    99% {
      opacity: 0.99;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes flipBottom {
    0%,
    50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 0.99;
    }
    100% {
      opacity: 0.99;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }
  @keyframes flipBottom {
    0%,
    50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 0.99;
    }
    100% {
      opacity: 0.99;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }